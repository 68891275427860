import I18n from "i18n-js";
import "./translations/session_errors";

export function showLoginModalOn(selector) {
  $(document).ready(() => {
    if (!sessionStorage.getItem("current-user-token")) {
      $(selector).on("click", (event) => {
        event.preventDefault();
        if (window.openLoginModal) {
          window.openLoginModal();
        } else {
          $("#modern-login__modal")?.modal_four("show");
        }
      });
    }
  });
}

export function loginUserAjax(formSelector) {
  $(document).ready(() => {
    $(`${formSelector} .modern-cta-button`).on("click", (event) => {
      event.preventDefault();

      return loginUser(formSelector);
    });
  });
}

function getLinkAndDescriptionForError(error) {
  const CURRENT_THEME = sessionStorage.getItem("currentTheme");
  switch (error) {
    case "Your account is locked.":
      return {
        text: error,
        link: "Did not receive an unlock email?",
        href: "/users/unlock/new",
      };
    case "Ihr Konto ist gesperrt.":
      return {
        text: error,
        link: "Keine Anleitung zum Entsperren erhalten?",
        href: "/users/unlock/new",
      };
    case "Sie müssen Ihr Konto bestätigen, bevor Sie fortfahren können.":
      return {
        text: error,
        link: "Keine Anleitung zur Bestätigung erhalten?",
        href: "/users/confirmation/new",
      };
    case "You have to confirm your email address before continuing.":
      return {
        text: error,
        link: "Did not receive your confirmation email?",
        href: "/users/confirmation/new",
      };
    case "You have one more attempt before your account is locked.":
      return {
        text: error,
        link: "",
        href: "/",
      };
    case "Sie haben noch einen Versuch, bis Ihr Konto gesperrt wird.":
      return {
        text: error,
        link: "",
        href: "/",
      };
    default:
      return {
        text: I18n.t(`${CURRENT_THEME}.errors.login.invalid`),
        link: null,
        href: null,
      };
  }
}

async function loginUser(formSelector) {
  const tokenElement = document.querySelector("[name=csrf-token]");
  const token = tokenElement ? tokenElement.content : null;

  const response = await fetch("/users/sign_in.json", {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify({
      user: {
        email: $(formSelector).find("#user_email").val(),
        password: $(formSelector).find("#user_password").val(),
      },
    }),
  });

  if (response.status === 401) {
    const json = await response.json();
    const errorMessage = getLinkAndDescriptionForError(json.error);
    $(`${formSelector} .form-input-set_modern .modern_input_error`).remove();
    $(`#modal-form-main .modern_input_error_login`).remove();

    if (errorMessage.href) {
      $(`#modal-form-main`).prepend(
        `<div class="modern_input_error_login" style="width:100%!important; display:none">
          <div class="alert_image">
          </div>
          <div class="alert_description">${errorMessage.text}</div>
          <div class="alert_link">
            <a class="devise_link" href="${errorMessage.href}">${errorMessage.link}</a>
          </div>
        </div>`
      );
      $(`#modal-form-main .modern_input_error_login`).fadeIn(1000);
    } else {
      $(`${formSelector} .form-input-set_modern`).append(
        `<div class="modern_input_error" style="display: none">${errorMessage.text}</div>`
      );
      $(`${formSelector} .form-input-set_modern .modern_input_error`).fadeIn(1000);
    }
  } else {
    window.location.reload();
  }
}
