/* eslint-disable */
import I18n from "i18n-js";

I18n.defaultLocale = sessionStorage.getItem("defaultLocale");
I18n.locale = sessionStorage.getItem("locale");
I18n.translations || (I18n.translations = {});
I18n.translations.de = I18n.extend(I18n.translations.de || {}, {
  "2ustand": {
    errors: {
      login: {
        invalid: "E-Mail-Adresse oder Passwort ist nicht korrekt.",
      },
    },
  },
  "367n": {
    errors: {
      login: {
        invalid: "E-Mail-Adresse oder Passwort ist nicht korrekt.",
      },
    },
  },
  activerecord: { errors: null },
  ng: {
    errors: {
      login: {
        invalid: "E-Mail-Adresse oder Passwort ist nicht korrekt.",
      },
    },
  },
  smartevents: {
    errors: {
      login: {
        invalid: "E-Mail-Adresse oder Passwort ist nicht korrekt.",
      },
    },
  },
  levelup_hub: {
    errors: {
      login: {
        invalid: "E-Mail-Adresse oder Passwort ist nicht korrekt.",
      },
    },
  },
  xdays: {
    errors: {
      login: {
        invalid: "E-Mail-Adresse oder Passwort ist nicht korrekt.",
      },
    },
  },
});
I18n.translations.en = I18n.extend(I18n.translations.en || {}, {
  "2ustand": {
    errors: {
      login: {
        invalid: "Email or password is invalid.",
      },
    },
  },
  "367n": {
    errors: {
      login: {
        invalid: "Email or password is invalid.",
      },
    },
  },
  activerecord: { errors: null },
  ng: {
    errors: {
      login: {
        invalid: "Email or password is invalid.",
      },
    },
  },
  smartevents: {
    errors: {
      login: {
        invalid: "Email or password is invalid.",
      },
    },
  },
  levelup_hub: {
    errors: {
      login: {
        invalid: "Email or password is invalid.",
      },
    },
  },
  xdays: {
    errors: {
      login: {
        invalid: "Email or password is invalid.",
      },
    },
  },
});
